"use client";
import { useCallback, useEffect, useMemo, useState } from "react";
import { OrganizationModel } from "./OrganizationModel";
import "./OrganizationSelect.css";

type Props = {
  tooltip?: string;
  hidden?: boolean;
  items: OrganizationModel[];
  value?: OrganizationModel;
  onChange: (organizationId: string | undefined) => void;
};

function formatName(name: string) {
  if (name.startsWith("Ortsgruppe")) {
    return name.replace("Ortsgruppe", "OG");
  }
  if (name.startsWith("Landesverband")) {
    return name.replace("Landesverband", "LV");
  }
  if (name.startsWith("Bezirk")) {
    return name.replace("Bezirk", "BZ");
  }
  if (name.startsWith("Kreisverband")) {
    return name.replace("Kreisverband", "KV");
  }
  return name;
}

export default function OrganizationSelect(props: Readonly<Props>) {
  const currentSelection = props.value?.id;
  const sortedItems = useMemo(
    () => [...props.items].sort((o1, o2) => o1.name.localeCompare(o2.name)),
    [props.items]
  );

  const [selectedValue, setSelectedValue] = useState<string | undefined>(
    currentSelection
  );

  const onChange = useCallback(
    (organizationId: string | undefined) => {
      setSelectedValue(organizationId);
      props.onChange(organizationId);
    },
    [props.onChange]
  );

  const onUserChangedOrganizationSelection = (
    organizationId: string | undefined
  ) => {
    if (selectedValue !== organizationId) {
      onChange(organizationId);
    }
  };

  useEffect(() => {
    if (selectedValue === undefined) {
      // this happens on page load
      onChange(undefined);
    }
  }, [selectedValue, onChange]);

  useEffect(() => {
    if (currentSelection !== selectedValue) {
      setSelectedValue(currentSelection);
    }
  }, [currentSelection, selectedValue]);

  if (sortedItems.length === 0 || selectedValue === undefined) {
    return <p>{props.value?.name}</p>;
  }

  return (
    <form
      className="form-inline my-2 my-lg-0 organization-form"
      hidden={props.hidden}
    >
      <select
        className="form-select"
        id="select-organization"
        value={selectedValue}
        onChange={(event) =>
          onUserChangedOrganizationSelection(event.target.value)
        }
      >
        {sortedItems.map((organization) => (
          <option key={organization.id} value={organization.id}>
            {formatName(organization.name)}
          </option>
        ))}
      </select>
    </form>
  );
}
