import FindUserProfileAction from "@/lib/integration/features/Profile/FindUserProfileAction";
import { usePathname } from "next/navigation";
import { useCallback, useEffect, useMemo, useState } from "react";
import { OrganizationModel } from "../../layout/OrganizationSelect/OrganizationModel";
import { UserProfileModel } from "../UserProfileModel";
import { UserProfile } from "./UserProfile";
import useSelectedOrganizationStorage from "./useSelectedOrganizationStorage";
import { isOnClientSide } from "@/lib/infrastructure/serverclient";

export default function useUserProfile(
  currentState: UserProfileModel
): UserProfile {
  const initialValue = useMemo(() => currentState, [currentState]);
  const [userProfile, setUserProfile] =
    useState<UserProfileModel>(initialValue);

  const [selectedOrganization, setSelectedOrganization] = useState<OrganizationModel | undefined>();

  const onSelectedOrganizationChanged = (value: OrganizationModel | null) => {
    setSelectedOrganization(value ?? undefined)
  };

  const selectedOrganizationCache = useSelectedOrganizationStorage(onSelectedOrganizationChanged);

  function findOrganizationToSelect(
    organizations: OrganizationModel[],
    organization?: OrganizationModel
  ): OrganizationModel | undefined {
    const cachedSelectedOrganization = selectedOrganizationCache.get();
    let organizationToSelect: OrganizationModel | undefined = undefined;

    if (organizations.length > 0) {
      organizationToSelect = organization;
      if (!organizationToSelect) {
        if (cachedSelectedOrganization) {
          organizationToSelect = cachedSelectedOrganization;
        }
      }

      if (organizationToSelect) {
        // Check if organization to select still exists in the list
        organizationToSelect = organizations.find(
          (org) => org.id === organizationToSelect?.id
        );
      }

      if (!organizationToSelect) {
        organizationToSelect = organizations[0];
      }
    }

    return organizationToSelect;
  }

  const selectOrganizationForUsingProfile = useCallback(
    (
      userProfile: UserProfileModel,
      organizationIdToSelect: string | undefined
    ) => {
      const organization = userProfile.organizations.find(
        (org) => org.id === organizationIdToSelect
      );
      const organizationToSelect = findOrganizationToSelect(
        userProfile.organizations,
        organization
      );
      if (organizationToSelect?.id !== selectedOrganization?.id) {
        selectedOrganizationCache.put(organizationToSelect);
        setSelectedOrganization(organizationToSelect);
      }
    },
    [selectedOrganization?.id]
  );

  const onOrganizationSelectionChanged = useCallback(
    (organizationId: string | undefined) => {
      FindUserProfileAction().then((newState) => {
        if (JSON.stringify(userProfile) !== JSON.stringify(newState)) {
          setUserProfile(newState);
        }

        selectOrganizationForUsingProfile(newState, organizationId);
      });
    },
    [selectedOrganization?.id, userProfile, selectOrganizationForUsingProfile]
  );

  const pathname = usePathname();
  const [currentPath, setCurrentPath] = useState<string>(pathname);

  useEffect(() => {
    if (currentPath !== pathname) {
      setCurrentPath(pathname);
      onOrganizationSelectionChanged(selectedOrganization?.id);
    }
  }, [
    pathname,
    currentPath,
    onOrganizationSelectionChanged,
    selectedOrganization?.id,
  ]);

  return {
    get: userProfile,
    update: () => onOrganizationSelectionChanged(selectedOrganization?.id),
    onOrganizationSelectionChanged,
    selectedOrganization,
  };
}
