import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/common/BootstrapClient.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/global/helper/UserProfileProvider/UserProfileProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/global/layout/Header/Header.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/styles/global.scss");
;
import(/* webpackMode: "eager" */ "/app/src/components/global/layout/Footer/Footer.css");
