"use client";

import IconLink from "@/components/common/IconLink/IconLink";
import LinkButton from "@/components/common/LinkButton";
import { AppIcons } from "@/components/global/icons/AppIcons";
import { Auth } from "@/lib/integration/auth/Auth";
import { TFunction } from "i18next";
import useUserProfileContext from "../../helper/UserProfileProvider/useUserProfileContext";
import OrganizationSelect from "../OrganizationSelect/OrganizationSelect";
import "./UserStatus.css";
import logIn = Auth.logIn;
import logout = Auth.logOut;
import { Trans } from "react-i18next/TransWithoutContext";

type Props = {
  lang: string;
  selectOrganizationTooltip?: string;
  t: TFunction<string, undefined>;
};
export default function UserStatus(props: Readonly<Props>) {
  const profile = useUserProfileContext();
  const userProfile = profile.get;

  if (userProfile === undefined) {
    return null;
  }

  const isAdministrationIconVisible = userProfile.isGlobalAdmin;
  const isOrganizationIconVisible = userProfile.organizations.length > 0;
  const isProfileIconVisible = userProfile.isLoggedIn;
  const isLoginButtonVisible = !userProfile.isLoggedIn;
  const isLogoutButtonVisible = userProfile.isLoggedIn;

  return (
    <div className="loginStatus">
      {isAdministrationIconVisible && (
        <IconLink
          href={`/${props.lang}/administration`}
          icon={AppIcons.Administration}
          tooltip={props.t("Tooltip.Administration")}
          tooltipPlacement="bottom"
          id="permissions-link"
        />
      )}
      {isOrganizationIconVisible && (
        <IconLink
          href={`/${props.lang}/organizations/current`}
          icon={AppIcons.Organization}
          tooltip={props.t("Tooltip.Organization")}
          tooltipPlacement="bottom"
          id="permissions-link"
        />
      )}
      {isOrganizationIconVisible && (
        <OrganizationSelect
          tooltip={props.selectOrganizationTooltip}
          items={userProfile.organizations}
          value={profile.selectedOrganization}
          onChange={profile.onOrganizationSelectionChanged}
        />
      )}
      {isProfileIconVisible && (
        <IconLink
          icon={AppIcons.UserIcon}
          tooltip={props.t("Tooltip.Profile")}
          tooltipPlacement="bottom"
          href="/profile/me"
          id="profile"
        />
      )}
      {isLogoutButtonVisible && (
        <IconLink
          id="logout"
          icon={AppIcons.PowerOffIcon}
          onClick={() => logout()}
          tooltip={props.t("Tooltip.Logout")}
          tooltipPlacement="bottom"
          showLoadingIndicatorOnClick
        />
      )}
      {isLoginButtonVisible && (
        <LinkButton id="login" icon={undefined} onClick={() => logIn()}>
          <Trans t={props.t} i18nKey="Login" />
        </LinkButton>
      )}
    </div>
  );
}
