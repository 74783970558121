"use client";
import { UserProfileModel } from "../UserProfileModel";
import { UserProfileContext } from "./UserProfileContext";
import useUserProfile from "./useUserProfile";

export type Props = {
  userProfile: UserProfileModel;
  children: any;
};
export default function UserProfileProvider(props: Readonly<Props>) {
  const userProfile = useUserProfile(props.userProfile);
  return (
    <UserProfileContext.Provider value={userProfile}>
      {props.children}
    </UserProfileContext.Provider>
  );
}
