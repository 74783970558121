"use client";

import IconLink from "@/components/common/IconLink/IconLink";
import { AppIcons } from "@/components/global/icons/AppIcons";
import MainNavigation from "@/components/global/layout/Navigation/MainNavigation";
import UserStatus from "@/components/global/layout/UserStatus/UserStatus";
import { useTranslation } from "@/i18n/client";
import { usePathname } from "next/navigation";
import "./Header.css";
import { Suspense } from "react";

type Props = {
  lang: string;
};
export default function Header(props: Readonly<Props>) {
  const { t } = useTranslation(props.lang, "header");
  const pathname = usePathname();

  return (
    <header>
      <MainNavigation {...props} t={t} path={pathname}>
        <IconLink
          hidden={true}
          href={`/${props.lang}/help`}
          icon={AppIcons.CircleQuestionIcon}
          tooltip={t("Tooltip.Help")}
          tooltipPlacement="bottom"
          id="help"
        />
        <Suspense fallback={<p>Loading...</p>}>
          <UserStatus t={t} lang={props.lang} />
        </Suspense>
      </MainNavigation>
    </header>
  );
}
