import { isOnClientSide } from "@/lib/infrastructure/serverclient";
import { OrganizationModel } from "../../layout/OrganizationSelect/OrganizationModel";

function getStorage(): Storage {
  return window.localStorage;
}

export default function useSelectedOrganizationStorage(
  onSelectedOrganizationChanged: (value: (OrganizationModel | null)) => void
) {
  const STORAGE_KEY = "selectedOrganizationId";

  if (isOnClientSide) {
    window.addEventListener("storage", (event) => {
      if (event.key !== STORAGE_KEY) {
        return;
      }
      if (event.storageArea !== getStorage()) {
        return;
      }
      onSelectedOrganizationChanged(getSelectedOrganization());
    });
  }

  function saveSelectedOrganization(
    organization: OrganizationModel | undefined
  ): void {
    if (isOnClientSide) {
      const storage = getStorage();
      if (organization === undefined) {
        storage.removeItem(STORAGE_KEY);
      } else {
        storage.setItem(STORAGE_KEY, JSON.stringify(organization));
      }
    }
  }

  function getSelectedOrganization(): OrganizationModel | null {
    let organization = null;
    if (isOnClientSide) {
      const organizationJson = getStorage().getItem(
        STORAGE_KEY
      ) as string;

      if (organizationJson !== null) {
        try {
          organization = JSON.parse(organizationJson) as OrganizationModel;
        } catch (error) {
          getStorage().removeItem(STORAGE_KEY);
        }
      }
    }
    return organization;
  }

  return {
    put: saveSelectedOrganization,
    get: getSelectedOrganization,
  };
}
