import { Session } from "next-auth";
import { NextAuth } from "../../infrastructure/next-auth/next-auth";
import { signIn, signOut } from "next-auth/react";

export namespace Auth {
  export async function isUserLoggedIn(): Promise<boolean> {
    return (await getUserName()) !== undefined;
  }

  export async function getUserName(): Promise<string | undefined> {
    try {
      const session = await NextAuth.getServerSessionWithAuthentication();
      const username = session?.user?.name;
      if (username) {
        return username;
      }
      return undefined;
    } catch (e) {
      console.warn("Error while getting username: ", e);
      return undefined;
    }
  }

  export async function getEmail(): Promise<string | undefined> {
    try {
      const session = await NextAuth.getServerSessionWithAuthentication();
      const email = session?.user?.email;
      if (email) {
        return email;
      }
      return undefined;
    } catch (e) {
      console.warn("Error while getting email: ", e);
      return undefined
    }
  }

  const whitelist: string[] = ["^http[s]?:\\/\\/[\\d\\w:\\-.]+\\/(de|en)\\/competitions\\/[\\d\\w]*$",
    "^http[s]?:\\/\\/[\\d\\w:\\-.]+\\/(de|en)\\/registrations\\/[\\d\\w]*$"];

  function isOnWhitelist(url: string): boolean {
    for (let i = 0; i < whitelist.length; i++) {
      if (url.match(whitelist[i])) {
        return true;
      }
    }
    return false;
  }

  export async function logOut() {
    const callbackUrl: string = isOnWhitelist(window?.location?.href || "") ? window?.location?.href || "" : "/";
    return signOut({ callbackUrl: callbackUrl })
  }

  export async function logIn() {
    return signIn("keycloak", {callbackUrl: window?.location?.href || "/", redirect: false});
  }

  export async function getAccessToken(): Promise<string | undefined> {
    const session = await NextAuth.getServerSessionWithAuthentication();
    return session?.access_token;
  }

  class AuthUser {
    constructor(public name: string, public sub: string) {
    }
  }

  class AuthSession {
    constructor(
      public iat: number,
      public expires_at: number,
      public access_token: string,
      public refresh_token: string
    ) {
    }
  }

  export class AuthState {
    constructor(
      public username: string,
      public userId: string,
      public issuedAt: Date,
      public expiresAt: Date,
      public accessToken: string,
      public refreshToken: string
    ) {
    }
  }

  export async function getAuthState() {
    const session = await NextAuth.getServerSessionWithAuthentication();
    const user = session?.user as AuthUser;
    const authSession = session as AuthSession;
    return new AuthState(
      user.name,
      user.sub,
      new Date((authSession?.iat ?? 0) * 1000),
      new Date((authSession?.expires_at ?? 0) * 1000),
      authSession?.access_token,
      authSession?.refresh_token
    );
  }

  export async function getSession(): Promise<Session | null> {
    return await NextAuth.getServerSessionWithAuthentication();
  }
}
